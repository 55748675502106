<template>
  <div v-if="loaded" class="advisor_container d-flex flex-row justify-content-start align-items-center">
    <div class="advisor_picture">
      <img v-if="Contacts[0].Gender === 'male'" src="@/assets/images/male-bg-white.svg" class="support-icon" />
      <img v-else src="@/assets/images/female-bg-white.svg" class="support-icon" />
    </div>
    <div class="advisor_tel_logo d-flex flex-row justify-content-center align-items-center">
      <i class="bi bi-telephone"></i>
    </div>
    <div class="advisor_contact">
      <p class="name">{{ Contacts[0].Contact_Name }}</p>
      <a href="#" class="phone" @click="call(Contacts[0].Phones_Numbers[0])">
        <strong>{{ Contacts[0].Phones_Numbers[0].toString().replace(/\B(?=(\d{2})+(?!\d))/g, ".") }}</strong>
      </a>
    </div>
  </div>
</template>

<script>
import getPhonesByUser from '../mixins/getPhonesByUser';
import getAdvisorRequest from '../services/graphql/requests/getAdvisorRequest';

export default {
  data() {
    return {
      // si données async sont chargées alors les données sont affichées dans le template
      loaded: false,
      Contacts: [],
    };
  },
  created() {
    this.Contacts = this.getCanContact();
    this.getData();
  },
  methods: {
    /**
     * @param {User} user
     * @param {array} phones
     * @returns {object}
     */
    createContact(user, phones) {
      return {
        Contact_Name: user.firstname + ' ' + user.lastname,
        Phones_Numbers: phones,
        Gender: user.title == 'Monsieur' ? 'male' : 'female'
      };
    },
    getData() {
      getAdvisorRequest().then(async (data) => {
        const advisor = data.client.rc;
        // 947 utilisateur fictif a qui sont attribués les BFI
        if (advisor && advisor.id !== '947') {
          // get phones for advisor
          const phones = this.getPhonesByUser(advisor);
          if (phones.length) {
            this.setContactsWithAdvisorAndPhones(advisor, phones);
          }
          // get other contacts
          const contacts = await this.getSuperiorsContactByUser(advisor);
          this.pushContacts(contacts);
          this.loaded = true;
        }
      }).catch((reason) => {
        console.error(reason);
      });
    },
    /**
     * pushContacts
     * @param {User[]} contacts
     */
    pushContacts(contacts) {
      if (contacts.length) {
        for (let i = 0; i < contacts.length; i++) {
          const phones = this.getPhonesByUser(contacts[i]);
          const contact = this.createContact(contacts[i], phones, true);
          this.Contacts.push(contact);
        }
      }
    },
    /**
     * setContactsWithAdvisorAndPhones
     *
     * @param {User} advisor
     * @param {array} phones
     */
    setContactsWithAdvisorAndPhones(advisor, phones) {
      this.Contacts = [this.createContact(advisor, phones)];
    },
    call(phoneNumber) {
      window.location.href = 'tel:' + phoneNumber
    },
  },
  mixins: [getPhonesByUser]
}
</script>

<style scoped lang="scss">
@import '../assets/css/colors.scss';

.advisor_container {
  z-index: 9;
  height: 75px;
  width: 236px;
  background-color: $color-primary;
  position: fixed;
  right: -5px;
  bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  color: white;
}

.advisor_picture > img {
  margin: 0;
  margin-left: 5.57px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.bi-telephone {
  font-size: 15px;
  margin-right: 10px;
}

.advisor_contact {
  font-size: 14px;

  p {
    padding: 0;
    margin: 0;
    text-align: left;
  }
}

a {
  text-decoration: none;
  color: white;
  :hover {
    text-decoration: underline;
  }
}
</style>
