<template>
  <nav class="navbar navbar-expand-lg navbar-dark nav-bar-bg " v-bind:class="{ 'menu-shadow' : displayShadow }">
    <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#toggleMobileMenu"
        aria-controls="toggleMobileMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="switchShadow"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <a href="#" class="navbar-brand ps-5 pe-4 order-lg-1">
      <img src="@/assets/images/logo_patrimial.png" class="logo-ymanci-header"/>
    </a>

    <ul class="navbar-nav ms-auto text-center order-lg-3">
      <li class="dropdown nav-bar-arrow pointer profil navbar_dropdown" @click="triggerDropDownMenu()">
        <a
            href="#"
            class="nav-link dropdown-toggle text-white"
            id="dropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        ><img src="@/assets/images/profil.svg" class="navbar_profil_logo"/><b class="d-none d-lg-inline navbar_name">{{ fullName }}</b></a>
        <ul
            class="dropdown-menu dropdown-menu-position" v-bind:class="{ show : isActive }"
            aria-labelledby="dropdown"
        >
          <li>
            <router-link class="dropdown-item sidebar-text" :to="MY_PROFILE_PATH">Mon profil</router-link>
          </li>
          <div class="dropdown-divider sidebar-text"></div>
          <li>
            <a @click="confirmLogOut" class="dropdown-item sidebar-text" href="#">Déconnexion</a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="collapse navbar-collapse order-lg-2" id="toggleMobileMenu" ref="menu">
      <SideMenu class="d-lg-none"/>
    </div>
    <ConfirmLogoutAccountPopup />
  </nav>
</template>
<script>
import {computed} from "vue";
import {mapActions, useStore} from "vuex";
import store from "@/store";
import emitter from '../events/emitter.js';
import Client from '../models/client';
import { MY_PROFILE_PATH } from '../router/pathes';
import ConfirmLogoutAccountPopup from "./Popups/ConfirmLogoutAccountPopup.vue";
import SideMenu from "../components/SideMenu.vue";

export default {
  name: 'NavBar',
  props: {

  },
  components: {
    ConfirmLogoutAccountPopup,
    SideMenu
  },
  created() {
    this.MY_PROFILE_PATH = MY_PROFILE_PATH;
  },
  setup() {
    const store = useStore();

    const fullName = computed(() => store.getters[`getUserFullName`]);
    const mainClient = computed(() => store.getters[`client/getMainClient`]);

    return {
      fullName,
      mainClient
    }
  },
  data() {
    return {
      isActive: false,
      displayShadow: true,
    }
  },
  methods: {
    ...mapActions('client', ['getMainClient']),
    getData() {
      // get client data
      if (!this.mainClient) {
        this.getMainClient().then((client) => {
          store.commit("setUserFullname", (new Client(client)).getFullname());
        });
      }
    },
    confirmLogOut() {
        emitter.emit('show-confirm-logout');
    },
    triggerDropDownMenu(){
        this.isActive = !this.isActive;
    },
    switchShadow() {
      this.displayShadow = !this.displayShadow;
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped lang="scss">
@import '../assets/css/colors.scss';

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(0, 66, 99, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.nav-bar-bg {
  background: white;
}

.menu-shadow {
  box-shadow: 0px 20px 26px #0000001D;
}

.profil {
  background: $color-primary;
  border-radius: 30px;
  margin-right: 8px;
}

.ico-pourcent {
  height: 13px;
}

@media (min-width: 1912px) {
  .navbar-brand {
    margin-left: 2%;
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    //align-items: flex-start;
    margin-left: 3% !important;
  }
}

.dropdown-menu-position {
  background: $color-primary;
  border: 0;
  //top: 51px !important;
  z-index: 0;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  left: 13px;
}

.dropdown-divider {
  background: white;
  margin-left: 15px;
  width: 81%
}

.dropdown .dropdown-item:hover { background-color: transparent; font-weight: bold; }

.sidebar-text {
  color: white;
}

.espace_perso { font-size: 12px; }

.navbar_dropdown {
  margin-right: 48px;
  padding-right: 6px;
}

.navbar_profil_logo {
  margin-left: 6px;
  margin-right: 12px;
}

.navbar_name {
  margin-right: 6px;
}
</style>
